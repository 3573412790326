/**
 * showing relationgroup table on index.
 */

let relationgroupsIndex = $('#relationgroupstable').DataTable({
    processing: true,
    fixedHeader: true,

    destroy: true,
    ajax: {
        url: "/relationgroup",
        type: 'GET',
    },
    rowId: 'id',
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        { data: 'name', name: 'name',},
        { data: 'size', name: 'size',},
        {
            data: null,
            render: function ( data, type, row ) {

                let $return = '';

                if (data.role >= 100) {
                    $return = $return + '<a title="Bekijk details" href="/relationgroup/'+data.id+'"><i class="ml-2 far fa-eye"  style="font-size: 24px;"></i></a>';
                    $return = $return + '<a title="Bewerking" href="/relationgroup/' + data.id + '/edit" name="'+data.id+'"><i class="ml-2 far fa-edit" style="font-size: 24px;"></i></a>';
                }


                if (data.role > 200) {
                    $return = $return + '<a title="Verwijderen" href="#deleterelationgroupModal" name="'+data.id+'" id="deleterelationgroup"><i class="ml-2 fas fa-trash"  style="font-size: 24px;"></i></a>';
                }

                return $return;

            },
            "orderable": false,
        }
    ],
    "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

/**
 * showing relationgroup table on show.
 */
let relationgroupshow = $('#relationgroupshowtable').DataTable({
    order: [[4, 'asc']],
    processing: true,
    fixedHeader: true,
    destroy: true,
    ajax: $.fn.dataTable.pipeline( {
        url: "/relationgroup/" + $('#relationgroup_id').val(),
        type: 'GET',
    }),
    rowId: 'id',
    drawCallback: function( settings ) {
        $('.removegroup').on('click', function(e) {
            var sure = confirm("Bevestig verwijderen persoon uit relatiegroep?");
            if (sure) {
                let id = $(this).attr('id');
                let relid = $(this).attr('name');

                $.ajax({
                    type: 'POST',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    dataType: 'json',
                    url: "/details/detachrelationgroup",
                    data: {
                        'relationgroup_id': relid,
                        'detail_id': id,
                    },
                    success: function (data) {
                        window.location.reload(false);
                    },
                    error: function (data) {
                        window.location.reload(false);
                    },
                });
            }
        });
    },
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        {
            data: 'contactdetails',
            orderable: false,
            render: function ( data, type, row ) {
                if (data.type == 'privateperson') {
                    return '<i class="far fa-user"></i> '+data.fullname;
                } else if (data.type == 'organisation')  {
                    return '<i class="far fa-building"></i> '+data.name;
                } else {
                    return '<i class="fas fa-briefcase"></i> '+data.name;
                }
            }
        },
        {
            data: 'contactdetails',
            orderable: false,
            render: function (data, type, row) {
                return data.email;
            }
        },
        {
            data: 'contactdetails',
            orderable: false,
            render: function (data, type, row) {
                return (data.telephone ? data.telephone : '')+(data.mobilephone && data.telephone ? ' / ' : '')+(data.mobilephone ? data.mobilephone : '');
            }
        },
        {
            data: null,
            render: function ( data, type, row ) {

                if (data.role > 200) {


                    let ret = '<a title="Bekijk details" href="/privateperson/'+data.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                    ret +=  '<a title="Bewerking" href="/privateperson/'+row.id+'/edit"><i class="ml-2 far fa-edit" style="font-size: 24px;"></i></a>';
                    ret += '<a title="Verwijderen" class="removegroup" id="'+data.id+'" name="'+data.relid+'" href="#"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                    return ret;


                    //return '<a title="Verwijderen" class="removegroup" id="'+data.id+'" name="'+data.relid+'" href="#"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                } else {
                    return '';
                }
            },

            "orderable": false,
        }
    ],
    "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

/**
 * Showing relationgroup table on edit.
 */
let relationgroupEditTable = $('#relationgroupEditTable').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    destroy: true,
    ajax: {
        url: "/relationgroup/" + $('#relationgroup_id').val(),
        type: 'GET',
    },

    rowId: 'id',
    drawCallback: function( settings ) {
        $('.removegroup').on('click', function(e) {
            let id = $(this).attr('id');
            let relid = $(this).attr('name');

            $.ajax({
                type : 'POST',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                dataType: 'json',
                url : "/details/detachrelationgroup",
                data:{
                    'relationgroup_id': relid,
                    'detail_id': id,
                },
                success:function(data){
                    //console.log(data);
                    relationgroupEditTable.draw();
                },
                error:function(data) {
                    //console.log(data);
                },
            });
        });
    },
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        {
            data: 'contactdetails',
            orderable: false,
            render: function (data, type, row) {
                if (data.type == 'privateperson') {
                    return '<i class="far fa-user"></i> '+data.fullname;
                } else if (data.type == 'organisation')  {
                    return '<i class="far fa-building"></i> '+data.name;
                } else {
                    return '<i class="fas fa-briefcase"></i> '+data.name;
                }
            }
        },
        {
            data: 'contactdetails',
            orderable: false,
            render: function (data, type, row) {
                return data.address + ' ' + data.houseNumber + ', ' + data.postalcode + ' ' + data.place;
            }
        },
        {
            data: 'contactdetails',
            orderable: false,
            render: function (data, type, row) {
                return data.email;
            }
        },
        {
            data: null,
            render: function ( data, type, row ) {

                if (data.role > 100) {
                    return '<a title="Verwijderen" class="removegroup" id="'+data.id+'" name="'+data.relid+'" href="#"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                } else {
                    return '';
                }
            },

            "orderable": false,
        }
    ],
    "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});


let addcontacttogroup = $('#addcontacttogroup').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    destroy: true,
    lengthMenu: [[ 10, 50, 100, -1 ], [10, 50, 100, "Alles"]],
    pageLength: 10,
    ajax: $.fn.dataTable.pipeline( {
        url: "/details/all",
        pages: 10,
        type: 'POST',
        success:function(data){
            console.log(data);
        },
        error:function(data) {
            console.log(data);
        },
    }),

    rowId: 'id',
    deferRender: true,
    autoWidth: true,
    select: {
        style:    'multi',
    },
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        {
            data: 'contactdetails',
            orderable: false,
            render: function (data, type, row) {
                if (data.type == 'privateperson') {
                    return '<i class="far fa-user"></i> '+data.fullname;
                } else if (data.type == 'organisation')  {
                    return '<i class="far fa-building"></i> '+data.name;
                } else {
                    return '<i class="fas fa-briefcase"></i> '+data.name;
                }
            }
        },
        {
            data: 'contactdetails',
            orderable: false,
            render: function ( data, type, row ) {
                return (data['telephone'] ? data['telephone'] : data['mobilephone'])+'<br>'+data['email']
            }
        },
        {
            data: 'id',
            name: 'checkboxes',
            defaultContent: '',
            orderable: false,
            checkboxes: {
                selectRow: true,
            }
        },
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

$('#addtorelationgroup').on('click', function(e){
    let id = $('#groupid').val();
    let rows_selected = addcontacttogroup.column(3).checkboxes.selected();
    let ids = [];

    $.each(rows_selected, function(index, rowId){
        ids.push(rowId)
    });

    $.ajax({
        type : 'PUT',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : '/relationgroup/'+id+'/attachcontact',
        data:{
            'relationgroup_id': id,
            'contact_ids':  ids,
        },
        success:function(data){
            relationgroupEditTable.draw();
            addcontacttogroup.rows().deselect();
        },
        error:function(data) {
        }
    });
});

/**
 * Creating, editting and deleting relationgroups
 */
$(document).on('click', "#addrelationgroup", function() {
    // $('#create-edit-relationgroupmodal').modal('show');
});

$('#saverelationgroupbtn').on('click', function(e) {
    e.preventDefault();

    let relationgroupId = document.getElementById('relationgroupId');
    let relationgroupInput = document.getElementById('relationgroupInput');

    if(relationgroupId.value) {

        $.ajax(
        "/relationgroup/" + relationgroupId.value,
        {
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            data:{
                id: relationgroupId.value,
                name: relationgroupInput.value,
            },
            dataType: 'json',
            success: function(data){
                relationgroupsIndex.draw();
            },
            error: function(e){
                //
            },
            processData: true,
            type: 'PUT',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
    } else {
        $.ajax(
        "/relationgroup",
        {
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            data:{
                name: relationgroupInput.value,
            },
            dataType: 'json',
            success: function(data){
                window.location.href = "/relationgroup/" + data.id + "/edit";
            },
            error: function(e){
                //
            },
            processData: true,
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
    }
});

$(document).on('click', "#deleterelationgroup", function(e) {
    e.preventDefault();

    $.ajax(
        "/relationgroup/" + e.currentTarget.name + "/getData",
    {
        dataType: 'json',
        success:function(data){
            document.getElementById('relationgroupDelete').innerHTML = data.name;
            document.getElementById('relationgroupIdDelete').setAttribute('value', data.id);
        },
        error:function(data) {
            //
        },
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
    $('#deleterelationgroupModal').modal('show');
});

$("#deleterelationgroupbtn").on('click', function (e) {
    e.preventDefault();

    let relationgroupIdDelete = document.getElementById('relationgroupIdDelete');

    $.ajax(
        "/relationgroup/" + relationgroupIdDelete.value,
    {
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data:{
            id: relationgroupIdDelete.value,
        },
        dataType: 'json',
        success:function(data){
            $('#relationgroupIdDelete').val('');
            $("#relationgroupDelete").html('');
            $('#deleterelationgroupModal').modal('hide')
            relationgroupsIndex.draw();
        },
        error:function(data) {
            //console.log(data)
        },
        processData: true,
        type : 'DELETE',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
});

/**
 * attaching and detaching privateperson to relationgroup
 */

$("#attachPrivatepersonButton").on('click', function (e) {
    e.preventDefault();

    let relationgroup_id = document.getElementById('relationgroup_id');
    let privateperson_id = document.getElementById('privateperson_id');

    $.ajax(
        "/relationgroup/" + relationgroup_id.value + "/attachPrivateperson",
    {
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data:{
            privateperson_id: privateperson_id.value,
        },
        dataType: 'json',
        success:function(data){
            // console.log('Attached privateperson');
            $("#errorMessagePerson").html(data.error ?? '');
            relationgroupEditTable.draw();
        },
        error:function(data) {
            // console.log(data);
        },
        processData: true,
        type : 'PUT',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
});


/**
 * Attaching and detachen organisation from relationgroup
 */

$("#attachOrganisationButton").on('click', function (e) {
    e.preventDefault();

    let relationgroup_id = document.getElementById('relationgroup_id');
    let organisation_id = document.getElementById('organisation_id');

    $.ajax(
        "/relationgroup/" + relationgroup_id.value + "/attachOrganisation",
    {
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data:{
            organisation_id: organisation_id.value,
        },
        dataType: 'json',
        success:function(data){
            // console.log('attached organisation');
            $("#errorMessageOrganisation").html(data.error ?? '');
            relationgroupEditTable.draw();
        },
        error:function(data) {
            // console.log(data)
        },
        processData: true,
        type : 'PUT',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
});


/**
 * exporting data relationgroup
 */
$(document).on('click', "#exportRelationgroup", function(e) {
    e.preventDefault();

    $.ajax(
        "/relationgroup/" + e.currentTarget.name + "/showRelationgroup",
    {
        dataType: 'json',
        success:function(data){
            document.getElementById('exportRelationgroupName').innerHTML = data.name;
        },
        error:function(data) {
            // console.log(data);
        },
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
    $('#exportRelationgroupModal').modal('show');
});

$(document).on('click', "#exportRelationgroupBtn", function() {
    let relationgroup_id = document.getElementById('relationgroup_id');

    window.open('/relationgroup/' + relationgroup_id.value + '/export');
});

$(document).on('change', "#relationgroup_id", function() {
    let relid = document.getElementById('relationgroup_id').value;
    let mailcount = document.getElementById('mailcount');
    mailcount.textContent = `Aantal e-mail adressen ophalen...`
    $.ajax({
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/countemail",
        data:{
            'relationgroup_id': relid
        },
        success:function(data){
            mailcount.textContent = `Deze groep bevat ${data} unieke e-mail adressen`
        },
        error:function(data) {
            mailcount.textContent = `Kan aantal e-mail adressen niet bepalen`
        },
    });
});
