/* JQUERY */

import $ from 'jquery';
import './bootstrap';

import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-fixedheader-bs4';
import 'datatables.net-select-bs4';
import 'datatables.net-buttons-bs4';
import 'jquery-datatables-checkboxes';

import 'pc-bootstrap4-datetimepicker';

import 'select2';



$(function() {

    $('#datatable').DataTable( {
        "language": {
            "sProcessing": "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "Een moment geduld aub - bezig met laden...",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending":  ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        }
    });

});

/* CUSTOM JS */
require('../js/relations');
require('../js/additional');
require('../js/user');
require('../js/relationgroup');
require('../js/mail');



$(function() {
    // Sidebar toggle behavior
    $('#sidebarCollapse').on('click', function() {
      $('#sidebar, #content').toggleClass('active');
    });
  });



