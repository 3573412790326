import moment from 'moment';

$( document ).on('click', '#newmail', function() {
    window.location.href = "/email/create";
});

$("#preview").on('click', function () {
    $('#previewmailmodal').modal('show');
});

$("#shortcodes").on('click', function () {
    $('#shortcodesmodal').modal('show');
});

$("#missingmail").on('click', function () {
    $('#nomailmodal').modal('show');
});

let emailstableIndex = $('#emailstable').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    ajax: {
        url: "/email/saved",
        type: 'GET',
    },
    rowId: 'id',
    drawCallback: function( settings ) {
        delMail(emailstableIndex);
        queueMail(emailstableIndex);
    },
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        { data: 'to', name: 'to', },
        { data: 'subject', name: 'subject', },
        { data: 'updated_at', name: 'updated_at', },
        {
            data: null,
            orderable: false,
            render: function ( data, type, row ) {

                let $return = "";

                if (data.role > 10) {
                    $return = $return + '<a title="Bekijk details" href="/email/'+data.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                    $return = $return + '<a title="Bewerking" href="/email/'+row.id+'/edit"><i class="ml-2 far fa-edit" style="font-size: 24px;"></i></a>';
                    $return = $return + '<a title="Inplannen" href="/email/'+row.id+'/queue" ><i class="fas fa-mail-bulk ml-2" style="font-size: 24px;"></i></a>';
                }

                if (data.role > 200) {
                    $return = $return + '<a title="Verwijderen"  href="/email/'+row.id+'/delete"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                }

                return $return;
            }
        },
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

let queuedmailtableIndex = $('#queuedmailtable').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    ajax: {
        url: "/email/queued",
        type: 'GET',
    },
    rowId: 'id',
    drawCallback: function( settings ) {
        delMail(queuedmailtableIndex);
    },
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        { data: 'to', name: 'to', },
        { data: 'subject', name: 'subject', },
        { data: 'senddt', name: 'senddt', },
        {
            data: null,
            orderable: false,
            render: function ( data, type, row ) {

                let $return = "";

                if (data.role > 10) {
                    $return = $return + '<a title="Bekijk details" href="/email/'+data.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                }

                if (data.role > 100) {
                    $return = $return + '<a title="Bewerking" href="/email/'+row.id+'/edit"><i class="ml-2 far fa-edit" style="font-size: 24px;"></i></a>';
                }

                if (data.role > 200) {
                    $return = $return + '<a title="Verwijderen" id="delmail" name="'+row.id+'" href="#"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                }

                return $return;
            }
        },
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

let sendmailtableIndex = $('#sendmailtable').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    ajax: {
        url: "/email/send",
        type: 'GET',
    },
    rowId: 'id',
    drawCallback: function( settings ) {
        delMail(sendmailtableIndex);
    },
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        { data: 'to', name: 'to', },
        { data: 'subject', name: 'subject', },
        { data: {
                _: 'senddt.display',
                sort: 'senddt.timestamp'
            },
            name: 'senddt',},
        {
            data: null,
            orderable: false,
            render: function ( data, type, row ) {

                let $return = "";

                if (data.role >= 100) {
                    $return = $return + '<a title="Bekijk details" href="/email/'+data.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                }

                if (data.role > 200) {
                    $return = $return + '<a title="Verwijderen" id="delmail" name="'+row.id+'" href="#"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                }

                return $return;
            }
        },
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});


$(function () {
    $('#senddt').datetimepicker({
        locale: 'nl',
        minDate: moment(),
    });

    $('#senddatetime').val(moment().format('D-M-Y H:mm'));
    $('#editdatetime').val($('#datetime').val());
});

$(document).ready( function() {
    if ($('#from').val() == 0 ) {
        $('#signature1').show();
        $('#signature2').hide();
    } else {
        $('#signature1').hide();
        $('#signature2').show();
    }
})

$('#from').on('change', function() {
    if (this.value == 0 ) {
        $('#signature1').show();
        $('#signature2').hide();
    } else {
        $('#signature1').hide();
        $('#signature2').show();
    }

});



function delMail(table) {
    $("#delmail").on('click', function (e) {
        e.preventDefault();
        let row = e.currentTarget.name;
        $.ajax({
            type : 'DELETE',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: 'json',
            url : "/email/"+row,
            data:{
                //
            },
            success:function(data){
                table.draw();
            },
            error:function(data) {
                //
            }
        });
    });
}


function queueMail(table) {

    $("#queuemail").on('click', function (e) {

        e.preventDefault();
        let row = e.currentTarget.name;
        console.log('add to queue: ' + row);
        $.ajax({
            type : 'PUT',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: 'json',
            url : "/email/"+row+"/queuemail",
            data:{
                id: row
            },
            success:function(data){
                queuedmailtableIndex.draw();
                table.draw();
            },
            error:function(data) {
                //
            }
        });
    });
}
