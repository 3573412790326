/**
 * Creating table countries.
 */
let countryIndex = $('#countryTable').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    destroy: true,
    ajax: {
        url: "/country",
        type: 'GET',
    },
    rowId: 'id',
    columns: [
        { data: 'id', name: 'id', 'visible': false },
        { data: 'country', name: 'country' },
        {
            data: null,
            "orderable": false,
            render: function ( data, type, row ) {

                let $return = "";

                if (data.role > 200) {
                    $return += '<a title="Bewerking" href="#edit-countrymodal" id="editcountry" name="'+data.id+'"><i class="ml-2 far fa-edit" style="font-size: 24px;"></i></a>';
                }
                if (data.role > 800) {
                    $return += '<a title="Verwijderen" href="#deletecountryModal" name="'+data.id+'" id="deletecountry"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                }

                return $return;
            }
        }
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

/**
 * Creating table Title
 */
let titleIndex = $('#titleTable').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    destroy: true,
    ajax: {
        url: "/title",
        type: 'GET',
    },
    rowId: 'id',
    columns: [
        { data: 'id', name: 'id', 'visible': false },
        { data: 'title', name: 'title' },
        {
            data: null,
            "orderable": false,
            render: function ( data, type, row ) {

                let $return = "";

                if (data.role > 200) {
                    $return += '<a title="Bewerking" href="#edit-titlemodal" id="edittitle" name="'+data.id+'"><i class="ml-2 far fa-edit" style="font-size: 24px;"></i></a>';
                }
                if (data.role > 800) {
                    if (data.used === false) {
                        $return += '<a title="Verwijderen" href="deletetitleModal" name="' + data.id + '" id="deletetitle"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                    }
                }

                return $return;
            }
        }
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});


/**
 * Creating, updating, deleting countries.
 */

$(document).on('click', "#addcountry", function() {
    $('#create-countrymodal').modal('show');
});

$(document).on('click', "#editcountry", function(e) {
    e.preventDefault();

    $.ajax(
        "/country/" + e.currentTarget.name,
    {
        dataType: 'json',
        success:function(data){
            document.getElementById('countryInputEdit').setAttribute('value', data.country);
            document.getElementById('countryIdEdit').setAttribute('value', data.id);
        },
        error:function(data) {
            // console.log(data);
        },
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
    $('#edit-countrymodal').modal('show');
});

$('#savecountrybtn').on('click', function(e) {
    e.preventDefault();
    let countryInput = document.getElementById('countryInput');

    $.ajax(
    "/country",
    {
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data:{
            country: countryInput.value,
        },
        dataType: 'json',
        success: function(data){
            $('#countryInput').val('');
            $('#create-countrymodal').modal('hide');
            countryIndex.draw();
        },
        error: function(e){
            console.log(e);
        },
        processData: true,
        type: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});

$(document).on('click', "#updatecountrybtn", function(e) {
    e.preventDefault();

    $.ajax(
    "/country/" + document.getElementById('countryIdEdit').value,
    {
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data:{
            id: document.getElementById('countryIdEdit').value,
            country: document.getElementById('countryInputEdit').value,
        },
        dataType: 'json',
        success: function(data){
            $('#countryIdEdit').val('');
            $('#countryInputEdit').val('');
            $('#edit-countrymodal').modal('hide');
            countryIndex.draw();
        },
        error: function(e){
            console.log(e);
        },
        processData: true,
        type: 'PUT',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});

$(document).on('click', "#deletecountry", function(e) {
    e.preventDefault();

    $.ajax(
        "/country/" + e.currentTarget.name,
    {
        dataType: 'json',
        success:function(data){
            document.getElementById('countryDelete').innerHTML = data.country;
            document.getElementById('countryIdDelete').setAttribute('value', data.id);
        },
        error:function(data) {
            // console.log(data);
        },
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
    $('#deletecountryModal').modal('show');
});

$("#deletecountrybtn").on('click', function (e) {
    e.preventDefault();

    let countryIdDelete = document.getElementById('countryIdDelete');

    $.ajax(
        "/country/" + countryIdDelete.value,
    {
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        dataType: 'json',
        success:function(data){
            $('#countryIdDelete').val('');
            $("#countryDelete").html('');
            $('#deletecountryModal').modal('hide');
            countryIndex.draw();
        },
        error:function(data) {
            // console.log(data)
            $('#errorCountry').removeClass('d-none');
        },
        processData: true,
        type : 'DELETE',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
});



/**
 * Creating, updating, deleting title
 */

$(document).on('click', "#addtitle", function() {
    $('#create-titlemodal').modal('show');
});

$(document).on('click', "#edittitle", function(e) {
    e.preventDefault();

    $.ajax(
        "/title/" + e.currentTarget.name,
    {
        dataType: 'json',
        success:function(data){
            document.getElementById('titleInputEdit').setAttribute('value', data.title);
            document.getElementById('titleIdEdit').setAttribute('value', data.id);
        },
        error:function(data) {
            // console.log(data);
        },
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
    $('#edit-titlemodal').modal('show');
});

$('#savetitlebtn').on('click', function(e) {
    e.preventDefault();

    let titleInput = document.getElementById('titleInput');

    $.ajax(
    "/title",
    {
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data:{
            title: titleInput.value,
        },
        dataType: 'json',
        success: function(data){
            $('#titleIdEdit').val('');
            $('#titleInput').val('');
            $('#create-titlemodal').modal('hide');
            titleIndex.draw();
        },
        error: function(e){
            console.log(e);
        },
        processData: true,
        type: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});

$(document).on('click', "#updatetitlebtn", function(e) {
    e.preventDefault();

    $.ajax(
    "/title/" + document.getElementById('titleIdEdit').value,
    {
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data:{
            id: document.getElementById('titleIdEdit').value,
            title: document.getElementById('titleInputEdit').value,
        },
        dataType: 'json',
        success: function(data){
            $('#titleIdEdit').val('');
            $('#titleInputEdit').val('');
            $('#edit-titlemodal').modal('hide');
            titleIndex.draw();
        },
        error: function(e){
            console.log(e);
        },
        processData: true,
        type: 'PUT',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});

$(document).on('click', "#deletetitle", function(e) {
    e.preventDefault();

    $.ajax(
        "/title/" + e.currentTarget.name,
    {
        dataType: 'json',
        success:function(data){
            document.getElementById('titleDelete').innerHTML = data.title;
            document.getElementById('titleIdDelete').setAttribute('value', data.id);
        },
        error:function(data) {
            // console.log(data);
        },
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
    $('#deletetitleModal').modal('show');
});

$("#deletetitlebtn").on('click', function (e) {
    e.preventDefault();

    let titleIdDelete = document.getElementById('titleIdDelete');

    $.ajax(
        "/title/" + titleIdDelete.value,
    {
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        dataType: 'json',
        success:function(data){
            $('#titleIdDelete').val('');
            $("#titleDelete").html('');
            $('#deletetitleModal').modal('hide');
            titleIndex.draw();
        },
        error:function(data) {
            // console.log(data)
            $('#errorTitle').removeClass('d-none');
        },
        processData: true,
        type : 'DELETE',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
});
