let userindex = $('#usertable').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    destroy: true,
    ajax: {
        url: "/user",
        type: 'GET',
    },
    "drawCallback": function( settings ) {
        var api = this.api();
        var response = api.ajax.json();
        // Hide last column if user is not admin
        if (response.data[0].role <= 200) {
            api.column(4).visible(false);
        }
    },
    rowId: 'id',
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        { data: 'name', name: 'name' },
        { data: 'email', name: 'email' },
        { data: 'userrole', name: 'userrole'},
        {
            data: null,
            "orderable": false,
            render: function ( data, type, row ) {

                let $return = "";

                if (data.role > 200) {
                    $return = $return + '<a title="Bewerking" href="#" id="edituser" name="'+data.id+'"><i class="ml-2 far fa-edit" style="font-size: 24px;"></i></a>';
                }
                if (data.role > 200) {
                    $return = $return + '<a title="Verwijderen" href="#deleteusermodal" name="'+data.id+'" data-toggle="modal" id="deleteuser" data-target="#deleteusermodal"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                }

                return $return;
            }
        }
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

$(document).on('click', "#adduser", function() {
    $('#create-usermodal').modal('show');
});


$("#saveuserbtn").on('click', function(e) {

    e.preventDefault();

    $.ajax({
        type : 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/user",
        data:{
            'name': $('#name').val(),
            'email':  $('#email').val(),
            'role':  $('#role').val(),
            'signature1': window.editor1.getData(),
            'signature2': window.editor2.getData(),
        },
        success:function(data, textStatus, xhr){
            $('#create-usermodal').modal('hide');
            userindex.draw();
            $('.warning').addClass('d-none')
        },
        error: function(data, textStatus, xhr) {
            $.each(JSON.parse(data.responseText).errors, function(index, value) {
                $('#'+index+'-error').removeClass('d-none');
            });
        },
    });
});

$(document).on('click', "#edituser", function(e) {

    $.ajax({
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/user/"+e.currentTarget.name,
        success:function(data){
            $('#editname').val(data.name);
            $('#editmail').val(data.email);
            $('#editrole').val(data.role);
            document.getElementById('role' + data.role).setAttribute('selected', true);
            if (data.signature1) {
                window.editor3.setData(data.signature1);
            }
            if (data.signature2) {
                window.editor4.setData(data.signature2);
            }
            $("#edituserbtn").attr('name', data.id);
            $('#editrole').trigger('change');
        },
        error:function(data) {
            //console.log(data);
        }
    });

    $('#editusermodal').modal('show');
});

$("#deleteuserbtn").on('click', function(e) {
    e.preventDefault();
    $.ajax({
        type : 'DELETE',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/user/"+$('#deleteuserbtn').attr('name'),
        success:function(data){
            $('#deleteusermodal').modal('hide');
            userindex.draw();
        },
        error:function(data) {
            //
        }
    });
});

$("#deleteusermodal").on('shown.bs.modal', function (e) {

    $.ajax({
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/user/"+$(e.relatedTarget).attr('name'),
        success:function(data){
            $("#delname").html(data.name);
            $("#deleteuserbtn").attr('name', data.id);
        },
        error:function(data) {
            //
        }
    });
});


$("#edituserbtn").on('click', function (e) {

    $.ajax({
        type : 'PUT',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/user/"+$('#edituserbtn').attr('name'),
        data:{
            'name':  $("#editname").val(),
            'email':  $("#editmail").val(),
            'role':  $("#editrole").val(),
            'signature1': window.editor3.getData(),
            'signature2': window.editor4.getData(),
        },
        success:function(data){
            $('#editusermodal').modal('hide')
            $('.warning').addClass('d-none')
            userindex.draw();
        },
        error:function(data) {
            console.log(data);
            $.each(JSON.parse(data.responseText).errors, function(index, value) {
                console.log(index)
                $('#edit'+index+'-error').removeClass('d-none');
            });
        }
    });
});


$( '#editusermodal' ).modal( {
	focus: false,

	// Do not show modal when innitialized.
	show: false
} );

$('#deleteProfilePhoto').on('click', function(e) {
    e.preventDefault();
    let id = document.getElementById('user_id');
    let deleteProfilePictureBtn = document.querySelector('#deleteProfilePhoto'); // needs to be hidden
    let deleteProfilePhotoFile = document.querySelector('.deleteProfilePhotoFile'); // needs to be shown
    let profilePhotoImage = document.querySelector('.profilePhotoImage'); // needs to be hidden

    $.ajax(
        "/user/" + id.value + "/deleteProfilePhoto",
    {
        dataType: 'json',
        success:function(data){
            deleteProfilePictureBtn.classList.add('d-none');
            profilePhotoImage.classList.add('d-none');
            deleteProfilePhotoFile.classList.remove('d-none');
        },
        error:function(data) {
            console.log(data);
        },
        type : 'PUT',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },

    });
});

