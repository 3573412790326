$('#addorg').hide();
//
// Pipelining function for DataTables. To be used to the `ajax` option of DataTables
//
$.fn.dataTable.pipeline = function ( opts ) {
    // Configuration options
    var conf = $.extend( {
        pages: 5,     // number of pages to cache
        url: '',      // script url
        data: null,   // function or object with parameters to send to the server
                      // matching how `ajax.data` works in DataTables
        method: 'GET' // Ajax HTTP method
    }, opts );

    // Private variables for storing the cache
    var cacheLower = -1;
    var cacheUpper = null;
    var cacheLastRequest = null;
    var cacheLastJson = null;

    return function ( request, drawCallback, settings ) {
        var ajax          = false;
        var requestStart  = request.start;
        var drawStart     = request.start;
        var requestLength = request.length;
        var requestEnd    = requestStart + requestLength;

        if ( settings.clearCache ) {
            // API requested that the cache be cleared
            ajax = true;
            settings.clearCache = false;
        }
        else if ( cacheLower < 0 || requestStart < cacheLower || requestEnd > cacheUpper ) {
            // outside cached data - need to make a request
            ajax = true;
        }
        else if ( JSON.stringify( request.order )   !== JSON.stringify( cacheLastRequest.order ) ||
                  JSON.stringify( request.columns ) !== JSON.stringify( cacheLastRequest.columns ) ||
                  JSON.stringify( request.search )  !== JSON.stringify( cacheLastRequest.search )
        ) {
            // properties changed (ordering, columns, searching)
            ajax = true;
        }

        // Store the request for checking next time around
        cacheLastRequest = $.extend( true, {}, request );

        if ( ajax ) {
            // Need data from the server
            if ( requestStart < cacheLower ) {
                requestStart = requestStart - (requestLength*(conf.pages-1));

                if ( requestStart < 0 ) {
                    requestStart = 0;
                }
            }

            cacheLower = requestStart;
            cacheUpper = requestStart + (requestLength * conf.pages);

            request.start = requestStart;
            request.length = requestLength*conf.pages;

            // Provide the same `data` options as DataTables.
            if ( typeof conf.data === 'function' ) {
                // As a function it is executed with the data object as an arg
                // for manipulation. If an object is returned, it is used as the
                // data object to submit
                var d = conf.data( request );
                if ( d ) {
                    $.extend( request, d );
                }
            }
            else if ( $.isPlainObject( conf.data ) ) {
                // As an object, the data given extends the default
                $.extend( request, conf.data );
            }

            return $.ajax( {
                "type":     conf.method,
                "url":      conf.url,
                "data":     request,
                "dataType": "json",
                "cache":    false,
                "success":  function ( json ) {
                    cacheLastJson = $.extend(true, {}, json);

                    if ( cacheLower != drawStart ) {
                        json.data.splice( 0, drawStart-cacheLower );
                    }
                    if ( requestLength >= -1 ) {
                        json.data.splice( requestLength, json.data.length );
                    }

                    drawCallback( json );
                }
            } );
        }
        else {
            json = $.extend( true, {}, cacheLastJson );
            json.draw = request.draw; // Update the echo for each response
            json.data.splice( 0, requestStart-cacheLower );
            json.data.splice( requestLength, json.data.length );

            drawCallback(json);
        }
    }
};

// Register an API method that will empty the pipelined data, forcing an Ajax
// fetch on the next draw (i.e. `table.clearPipeline().draw()`)
$.fn.dataTable.Api.register( 'clearPipeline()', function () {
    return this.iterator( 'table', function ( settings ) {
        settings.clearCache = true;
    } );
} );

let relationsindex = $('#relationstable').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    destroy: true,
    ajax: $.fn.dataTable.pipeline( {
        url: "/relations/all",
        pages: 10,
        type: 'POST',
    }),
    rowId: 'id',
    deferRender: true,
    success:function(data){
        //
    },
    error:function(data) {
        //
    },
    drawCallback:function(){
        $( '#relationstable tbody tr' ).css( 'height', '73px' );
    },
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        {
            data: 'contactdetails',
            orderable: false,
            render: function ( data, type, row ) {
                if (data.type == 'privateperson') {
                    return '<i class="far fa-user"></i> '+data.fullname;
                } else if (data.type == 'organisation')  {
                    return '<i class="far fa-building"></i> '+data.name;
                } else {
                    return '<i class="fas fa-briefcase"></i> '+data.name;
                }
            }
        },
        {
            data: 'contactdetails',
            orderable: false,
            render: function ( data, type, row ) {

                return (data.telephone != null ? data.telephone+'<br>' : '')+data.email;
            }
        },
        {
            data: null,
            orderable: false,
            render: function ( data, type, row ) {

                let $return = '';

                if (data.role >= 100 && data.role < 200) {
                    $return = $return + '<a title="Bekijk details" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                }

                if (data.role >= 200) {
                    $return = $return + '<a title="Bekijk details" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                    $return = $return + '<a title="Bewerking" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'/edit"><i class="ml-2 far fa-edit" style="font-size: 24px;"></i></a>';
                    $return = $return + '<a title="Verwijderen" href="#delete'+data.contactdetails.type+'Modal" name="'+data.contactdetails.id+'" data-toggle="modal" id="delete'+data.contactdetails.type+data.contactdetails.id+'" data-target="#delete'+data.contactdetails.type+'Modal"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                }

                return $return;
            }
        }
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

let commgroup = $('#commgroups').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    destroy: true,
    lengthMenu: [[ 5, 10, 50, -1 ], [5, 10, 50, "Alles"]],
    pageLength: 5,
    ajax: {
        url: "/privateperson/"+$('#ppid').val()+"/getrelationgroups",
        pages: 10,
        type: 'GET',
    },
    rowId: 'id',
    deferRender: true,
    success:function(data){
        //
    },
    error:function(data) {
        //
    },
    drawCallback: function( settings ) {
        $('.removegroup').on('click', function(e) {
            let id = $(this).attr('id');
            let relid = $(this).attr('name');

            $.ajax({
                type : 'POST',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                dataType: 'json',
                url : "/details/detachrelationgroup",
                data:{
                    'relationgroup_id': relid,
                    'detail_id': id,
                },
                success:function(data){
                    commgroup.draw();
                },
                error:function(data) {
                    //
                },
            });
        });
    },
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        { data: 'organisation', name: 'organisation'},
        { data: 'name', name: 'name'},
        {
            data: null,
            render: function ( data, type, row ) {

                if (data.role > 200) {
                    return '<a title="Verwijderen" class="removegroup" id="'+data.id+'" name="'+data.relid+'" href="#"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                } else {
                    return '';
                }
            }
        },
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

let orgcommgroup = $('#orgcommgroups').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    destroy: true,
    lengthMenu: [[ 5, 10, 50, -1 ], [5, 10, 50, "Alles"]],
    pageLength: 5,
    ajax: {
        url: "/organisation/"+$('#orgid').val()+"/getrelationgroups",
        pages: 10,
        type: 'GET',
    },
    rowId: 'id',
    deferRender: true,
    success:function(data){
        //
    },
    error:function(data) {
        //
    },
    drawCallback: function( settings ) {
        $('.removegroup').on('click', function(e) {

            let id = $(this).attr('id');
            let relid = $(this).attr('name');

            $.ajax({
                type : 'POST',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                dataType: 'json',
                url : "/details/detachrelationgroup",
                data:{
                    'relationgroup_id': relid,
                    'detail_id': id,
                },
                success:function(data){
                    orgcommgroup.draw();
                },
                error:function(data) {
                    //
                },
            });
        });
    },
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        { data: 'name', name: 'name'},
        {
            data: null,
            orderable: false,
            render: function ( data, type, row ) {

                if (data.role > 200) {
                    return '<a title="Verwijderen" class="removegroup" id="'+data.id+'" name="'+data.relid+'" href="#"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                } else {
                    return '';
                }
            }
        },
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

$('#addtogroup').on('click', function(e) {

    let details = $('#details').val();
    if (details.length === 0){
        alert("Geen contactgegevens geselecteerd");
    } else {
        $.ajax({
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: 'json',
            url: "/details/addtorelationgroups",
            data: {
                'details': $('#details').val(),
                'groups': $('#relationgroupselect').val(),
            },
            success: function (data) {
                commgroup.draw();
            },
            error: function (data) {
                //
            }
        });
    }
});

$('#orgaddtogroup').on('click', function(e) {
    let id = $('#orgid').val();
    $.ajax({
        type : 'PUT',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/organisation/"+id+"/addtorelationgroups",
        data:{
            'groups':  $('#relationgroupselect').val(),
        },
        success:function(data){
            orgcommgroup.draw();
        },
        error:function(data) {
            //
        }
    });
});

let orgsnewpersontable = $('#orgsnewperson').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    destroy: true,
    lengthMenu: [[ 5, 10, 50, -1 ], [5, 10, 50, "Alles"]],
    pageLength: 5,
    ajax: {
        url: "/privateperson/"+$('#ppid').val()+"/getorganisations",
        pages: 10,
        type: 'GET',
    },
    rowId: 'id',
    deferRender: true,
    autoWidth: true,
    drawCallback: function( settings ) {

        $('.removefromorg').on('click', function(e) {

            let id = this.parentNode.parentNode.id;

            $.ajax({
                type : 'PUT',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                dataType: 'json',
                url : "/organisation/"+id+"/detachPrivateperson",
                data:{
                    'privateperson_id': $('#ppid').val(),
                    'organisation_id': id,
                },
                success:function(data){

                    $("#details option[value='"+data.id+"']").remove();
                    $('#details').trigger('change');

                    orgsnewpersontable.draw();
                    commgroups.draw();
                },
                error:function(data) {
                    //
                }
            });
        });
    },
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        { data: 'contactdetails.name', name: 'contactdetails.name'},
        {
            data: 'contactdetails',
            orderable: false,
            render: function ( data, type, row ) {
                return (data.address ?? '')+(data.houseNumber ? ' '+data.houseNumber : '')+(data.postalcode ? (data.address || data.houseNumber ? ' ' : '')+data.postalcode : '')+(data.place ? ' '+data.place : '');
            }
        },
        {
            data: null,
            orderable: false,
            render: function ( data, type, row ) {

                let $return = '';

                if (data.role >= 100 && data.role < 200) {
                    $return = $return + '<a title="Bekijk details" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                }

                if (data.role >= 200) {
                    $return = $return + '<a title="Bekijk details" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                }

                if (data.role > 200) {
                    $return = $return + '<a title="Verwijderen" class="removefromorg" href="#"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                }

                return $return;
            }
        }
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

let personsinorg = $('#personsinorg').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    destroy: true,
    lengthMenu: [[ 5, 10, 50, -1 ], [5, 10, 50, "Alles"]],
    pageLength: 5,
    ajax: {
        url: "/organisation/"+$('#orgid').val()+"/getpersons",
        pages: 10,
        type: 'GET',
    },
    rowId: 'id',
    deferRender: true,
    autoWidth: true,
    drawCallback: function( settings ) {
        $('.removefromorg').on('click', function(e) {

            let id = this.parentNode.parentNode.id;

            $.ajax({
                type : 'PUT',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                dataType: 'json',
                url : "/organisation/"+$('#orgid').val()+"/detachPrivateperson",
                data:{
                    'privateperson_id': id,
                    // 'organisation_id': $('#orgid').val(),
                },
                success:function(data){
                    //
                    personsinorg.draw();
                },
                error:function(data) {
                    //
                }
            });
        });
    },
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        { data: 'name', name: 'name'},
        {
            data: 'data',
            name: 'data',
            render: function ( data, type, row ) {
                return (data['telephone'] ? data['telephone'] : data['mobilephone'])+'<br>'+data['email']
            }
        },
        {
            data: null,
            render: function ( data, type, row ) {

                $return = "";

                if (data.role >= 200) {
                    $return = $return + '<a title="Bewerking" href="/privateperson/'+data.id+'/edit"><i class="ml-2 far fa-edit" style="font-size: 24px;"></i></a>';
                }

                if (data.role > 200) {
                    $return = $return + '<a title="Verwijderen" class="removefromorg" href="#"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                }

                return $return;
            }
        },
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

let addpersontoorg = $('#addpersontoorg').DataTable({
    processing: true,
    fixedHeader: true,
    serverSide: true,
    destroy: true,
    lengthMenu: [[ 10, 50, 100, -1 ], [10, 50, 100, "Alles"]],
    pageLength: 10,
    ajax: $.fn.dataTable.pipeline( {
        url: "/relations/persons",
        pages: 10,
        type: 'POST',
    }),
    success:function(data){
        //
    },
    error:function(data) {
        //
    },
    rowId: 'id',
    deferRender: true,
    autoWidth: true,
    select: {
        style:    'multi',
    },
    columns: [
        { data: 'id', name: 'id', 'visible': false},
        { data: 'name', name: 'name'},
        {
            data: 'contactdetails',
            name: 'contactdetails',
            render: function ( data, type, row ) {
                return (data['telephone'] ? data['telephone'] : data['mobilephone'])+'<br>'+data['email']
            }
        },
        {
            data: 'id',
            name: 'checkboxes',
            defaultContent: '',
            orderable: false,
            checkboxes: {
                selectRow: true,
            }
        }
    ],
    language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },
});

$('#addtoorg').on('click', function(e){
    let id = $('#orgid').val();
    let rows_selected = addpersontoorg.column(3).checkboxes.selected();
    let ids = [];

    $.each(rows_selected, function(index, rowId){
        ids.push(rowId)
    });

    $.ajax({
        type : 'PUT',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : '/organisation/'+id+'/attachPrivateperson',
        data:{
            'organisation_id': id,
            'privateperson_id':  ids,
        },
        success:function(data){
            personsinorg.draw();
            addpersontoorg.rows().deselect();
        },
        error:function(data) {
            //
        }
    });
});

$('#orgsnewperson').on('click', 'tbody tr td', function () {

    if ($(this).index() == 2) return;
    let row = orgsnewpersontable.row($(this)).data();

    $('#org').val(row['id']); // Select the option with a value of n
    $('#org').trigger('change'); // Notify any JS components that the value changed
});

$('#org').on('change', function(e) {

    let orgid = $('#org').val();
    let id = $('#ppid').val();

    $.ajax({
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/privateperson/"+id+"/organisation/"+orgid+"/details",
        success:function(data){
            $('#addorg').hide();
            $('#compphone').val(data.phone);
            $('#compemail').val(data.email);
            $('#function').val(data.function);
        },
        error:function(data) {
            //
        }
    });
});

$('#addneworg').on('click', function(e) {

    $('#addorg').show();
});

$('#adddetailstoorg').on('click', function(e) {

    let id = $('#ppid').val();
    let orgid = $('#org').val();

    $.ajax({
        type : 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/detail",
        data:{
            'ppid': id,
            'orgid': orgid,
            'telephone':  $('#compphone').val(),
            'email':  $('#compemail').val(),
            'function':  $('#function').val(),
        },
        success:function(data){
            orgsnewpersontable.draw();
            $(".warning").addClass("d-none")

            var newOption = new Option(data.name, data.id, true, true);

            $('#details').append(newOption);
        },
        error:function(data) {
            $.each(JSON.parse(data.responseText).errors, function(index, value) {
                $('#'+index+'-error').removeClass('d-none');
            });
        }
    });
});

$('#addorgbtn').on('click', function(e) {

    $.ajax({
        type : 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/organisation",
        data:{
            'name':  $("#orgname").val(),
            'address':  $('#neworgaddress').val(),
            'houseNumber':  $('#neworghousnumber').val(),
            'postalcode':  $('#neworgpostalcode').val(),
            'place':  $('#neworgplace').val(),
            'phone':  $('#neworgphone').val(),
            'email':  $('#neworgemail').val(),
        },

        success:function(data){
            $(".warning").addClass("d-none")
            $('#addorg').hide();

            var newOption = new Option(data.name, data.id, true, true);
            // Append it to the select
            $('#org').append(newOption).trigger('change');
        },
        error:function(data) {
            $.each(JSON.parse(data.responseText).errors, function(index, value) {
                $('#'+index+'-error').removeClass('d-none');
            });
        }
    });
});

$('#all').on('mouseup', function(e) {
    $('#persons').removeClass("selected");
    $('#organisations').removeClass("selected");
    $(e.target).addClass("selected");
    let relationsindexa = $('#relationstable').DataTable({
        processing: true,
        fixedHeader: true,
        serverSide: true,
        destroy: true,
        ajax: $.fn.dataTable.pipeline( {
            url: "/relations/all",
            pages: 10,
            type: 'POST',
        }),
        rowId: 'id',
        deferRender: true,
        drawCallback:function(){
            $( '#relationstable tbody tr' ).css( 'height', '73px' );
        },
        columns: [
            { data: 'id', name: 'id', 'visible': false},
            {
                data: 'contactdetails',
                orderable: false,
                render: function ( data, type, row ) {
                    if (data.type == 'privateperson') {
                        return '<i class="far fa-user"></i> '+data.fullname;
                    } else if (data.type == 'organisation')  {
                        return '<i class="far fa-building"></i> '+data.name;
                    } else {
                        return '<i class="fas fa-briefcase"></i> '+data.name;
                    }
                }
            },
            {
                data: 'contactdetails',
                orderable: false,
                render: function ( data, type, row ) {

                    return (data.telephone != null ? data.telephone+'<br>' : '')+data.email;
                }
            },
            {
                data: null,
                orderable: false,
                render: function ( data, type, row ) {

                    let $return = '';

                    if (data.role >= 100 && data.role < 200) {
                        $return = $return + '<a title="Bekijk details" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                    }

                    if (data.role >= 200) {
                        $return = $return + '<a title="Bekijk details" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                    }

                    if (data.role > 200) {
                        $return = $return + '<a title="Bewerking" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'/edit"><i class="ml-2 far fa-edit" style="font-size: 24px;"></i></a>';
                        $return = $return + '<a title="Verwijderen" href="#delete'+data.contactdetails.type+'Modal" name="'+data.contactdetails.id+'" data-toggle="modal" id="delete'+data.contactdetails.type+data.contactdetails.id+'" data-target="#delete'+data.contactdetails.type+'Modal"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                    }

                    return $return;
                }
            }
        ],
    });
});

$('#persons').on('mouseup', function(e) {
    $(e.target).addClass("selected");
    $('#all').removeClass("selected");
    $('#organisations').removeClass("selected");
    let relationsindexp = $('#relationstable').DataTable({
        processing: true,
        fixedHeader: true,
        serverSide: true,
        destroy: true,
        ajax: $.fn.dataTable.pipeline( {
            url: "/relations/persons",
            pages: 10,
            type: 'POST',
        }),
        rowId: 'id',
        deferRender: true,
        drawCallback:function(){
            $( '#relationstable tbody tr' ).css( 'height', '73px' );
        },
        columns: [
            { data: 'id', name: 'id', 'visible': false},
            {
                data: 'name',
                render: function ( data, type, row ) {
                    return '<i class="far fa-user"></i> '+data;
                }
            },
            {
                data: 'contactdetails',
                orderable: false,
                render: function ( data, type, row ) {

                    return (data.telephone != null ? data.telephone+'<br>' : '')+data.email;
                }
            },
            {
                data: null,
                render: function ( data, type, row ) {

                    let $return = '';

                    if (data.role >= 100 && data.role < 200) {
                        $return = $return + '<a title="Bekijk details" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                    }

                    if (data.role >= 200) {
                        $return = $return + '<a title="Bekijk details" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                    }

                    if (data.role > 200) {
                        $return = $return + '<a title="Bewerking" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'/edit"><i class="ml-2 far fa-edit" style="font-size: 24px;"></i></a>';
                        $return = $return + '<a title="Verwijderen" href="#delete'+data.contactdetails.type+'Modal" name="'+data.contactdetails.id+'" data-toggle="modal" id="delete'+data.contactdetails.type+data.contactdetails.id+'" data-target="#delete'+data.contactdetails.type+'Modal"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                    }

                    return $return;
                }
            }
        ],
        columnDefs: [
            { "width": "33%", "targets": 0 },
            { "width": "33%", "targets": 1 },
        ],
        language: {
            url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
        },
    });
});

$('#organisations').on('mouseup', function(e) {
    $(e.target).addClass("selected");
    $('#all').removeClass("selected");
    $('#persons').removeClass("selected");
    let relationsindexo = $('#relationstable').DataTable({
        processing: true,
        fixedHeader: true,
        serverSide: true,
        destroy: true,
        order: [[ 1, "asc" ]],
        ajax: $.fn.dataTable.pipeline( {
            url: "/relations/organisations",
            pages: 10,
            type: 'POST',
        }),
        rowId: 'id',
        deferRender: true,
        drawCallback:function(){
            $( '#relationstable tbody tr' ).css( 'height', '73px' );
        },
        columns: [
            { data: 'id', name: 'id', 'visible': false},
            {
                data: 'name',
                render: function ( data, type, row ) {
                    return '<i class="far fa-building"></i> '+data;
                }
            },
            {
                data: 'contactdetails',
                orderable: false,
                render: function ( data, type, row ) {
                    return data.telephone+'<br>'+data.email;
                }
            },
            {
                data: null,
                orderable: false,
                render: function ( data, type, row ) {
                    let $return = '';
                    if (data.role >= 100 && data.role < 200) {
                        $return = $return + '<a title="Bekijk details" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                    }
                    if (data.role >= 200) {
                        $return = $return + '<a title="Bekijk details" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'"><i class="ml-2 far fa-eye" style="font-size: 24px;"></i></a>';
                    }
                    if (data.role > 200) {
                        $return = $return + '<a title="Bewerking" href="/'+data.contactdetails.type+'/'+data.contactdetails.id+'/edit"><i class="ml-2 far fa-edit" style="font-size: 24px;"></i></a>';
                        $return = $return + '<a title="Verwijderen" href="#delete'+data.contactdetails.type+'Modal" name="'+data.contactdetails.id+'" data-toggle="modal" id="delete'+data.contactdetails.type+data.contactdetails.id+'" data-target="#delete'+data.contactdetails.type+'Modal"><i class="ml-2 fas fa-trash" style="font-size: 24px;"></i></a>';
                    }
                    return $return;
                }
            }
        ],
        language: {
            url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
        },
    });
    $('#org').trigger('change');
});

$(function () {
    $('[data-toggle="popover"]').popover({
        trigger: 'focus',
        html : true,
    })
})

$(document).on('click', "#addperson", function() {
    $('#create-edit-privatepersonmodal').modal('show');
});

$(document).on('click', "#editprivateperson", function(e) {

    $.ajax({
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/privateperson/"+e.currentTarget.name,
        success:function(data){

            $('#persid').val(data.id);

            $('#mfirstletters').val(data.firstletters);
            $('#mfirstname').val(data.firstname);
            $('#minsertion').val(data.insertion);
            $('#mlastname').val(data.lastname);

            $('#mtitle_id').val(data.title_id).change();
            $('#mprefix_id').val(data.prefix_id).change();
            $('#mcountry_id').val(data.country_id).change();

            $('#maddress').val(data.address);
            $('#mhouseNumber').val(data.houseNumber);
            $('#mpostalcode').val(data.postalcode);
            $('#mplace').val(data.place);
            $('#mphone').val(data.phone);
            $('#memail').val(data.email);
        },
        error:function(data) {
            //
        }
    });

    $('#create-edit-privatepersonmodal').modal('show');
});

$('#saveprivatepersonbtn').on('click', function(e) {
    e.preventDefault();

    $.ajax({
        type : 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/privateperson",
        data:{
            'id': $('#persid').val(),
            'firstletters':  $('#mfirstletters').val(),
            'firstname':  $('#mfirstname').val(),
            'insertion':  $('#minsertion').val(),
            'lastname':  $('#mlastname').val(),
            'title_id':  $('#mtitle_id').val(),
            'address':  $('#maddress').val(),
            'houseNumber':  $('#mhouseNumber').val(),
            'postalcode':  $('#mpostalcode').val(),
            'place':  $('#mplace').val(),
            'country_id':  $('#mcountry_id').val(),
            'phone':  $('#mphone').val(),
            'email':  $('#memail').val(),
        },
        success:function(data){
            $('#persid').val('');

            $('#firstletters').val($('#mfirstletters').val()),
            $('#firstname').val($('#mfirstname').val()),
            $('#insertion').val($('#minsertion').val()),
            $('#lastname').val($('#mlastname').val()),
            $('#title').val($( '#mtitle_id option:selected' ).text());
            $('#country').val($( '#mcountry_id option:selected' ).text());
            $('#address').val($('#maddress').val()),
            $('#houseNumber').val($('#mhouseNumber').val()),
            $('#postalcode').val($('#mpostalcode').val()),
            $('#place').val($('#mplace').val()),
            $('#phone').val($('#mphone').val()),
            $('#email').val($('#memail').val()),

            $('#create-edit-privatepersonmodal').modal('hide');
            //relationsindex.draw();
            location.reload() //temp fix
            $('.warning').addClass('d-none')
        },
        error:function(data) {
            $.each(JSON.parse(data.responseText).errors, function(index, value) {
                $('#'+index+'-error').removeClass('d-none');
            });
        }
    });

});

$("#deleteprivatepersonModal").on('shown.bs.modal', function (e) {
    $.ajax({
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/privateperson/"+$(e.relatedTarget).attr('name'),
        success:function(data){

            $("#persid").val(data.id);
            $("#deletepersonname").append(data.lastname+", "+data.firstname);
        },
        error:function(data) {
            $("#deletepersonname").remove();
        }
    });
});

$("#canceldeleteprivatepersonbtn").on('click', function (e) {
    $("#deletepersonname").html('');
});

$("#deleteprivatepersonbtn").on('click', function (e) {
    $.ajax({
        type : 'DELETE',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/privateperson/"+$("#persid").val(),
        success:function(data){

            $('#persid').val('');
            $("#deletepersonname").html('');
            $('#create-edit-privatepersonmodal').modal('hide')
            //relationsindex.draw();
            location.reload() //temp fix
        },
        error:function(data) {
            $("#deletepersonname").html('');
        }
    });
});



$(document).on('click', "#addorganisation", function() {
    $('#create-edit-organisationmodal').modal('show');
});

$(document).on('click', "#editorganisation", function(e) {

    $.ajax({
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/organisation/"+e.currentTarget.name,
        success:function(data){

            $('#orgid').val(data.id);

            $('#omcountry_id').val(data.country_id).change();
            $('#omname').val(data.name);
            $('#omaddress').val(data.address);
            $('#omhouseNumber').val(data.houseNumber);
            $('#ompostalcode').val(data.postalcode);
            $('#omplace').val(data.place);
            $('#omphone').val(data.phone);
            $('#omemail').val(data.email);
        },
        error:function(data) {
            //
        }
    });
});

$('#saveorganisationbtn').on('click', function(e) {
    e.preventDefault();

    $.ajax({
        type : 'PUT',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/organisation/"+$('#orgid').val(),
        data:{
            'name':  $('#name').val(),
            'address':  $('#address').val(),
            'houseNumber':  $('#housenumber').val(),
            'postalcode':  $('#postalcode').val(),
            'place':  $('#place').val(),
            'country_id':  $('#country_id').val(),
            'phone':  $('#phone').val(),
            'email':  $('#email').val(),
            'url':  $('#url').val(),
        },
        success:function(data){
            // //relationsindex.draw();
            location.reload() //temp fix
            $('.warning').addClass('d-none')
        },
        error:function(data) {
            $.each(JSON.parse(data.responseText).errors, function(index, value) {
                $('#'+index+'-error').removeClass('d-none');
            });
        }
    });

});

$("#deleteorganisationModal").on('shown.bs.modal', function (e) {
    $.ajax({
        type : 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/organisation/"+$(e.relatedTarget).attr('name'),
        success:function(data){
            $("#orgid").val(data.id);
            $("#deleteorgname").append(data.name);
        },
        error:function(data) {
            //
        }
    });
});

$("#deleteorganisationModal").on('hide.bs.modal', function (e) {
    $('#orgid').val('');
    $("#deleteorgname").html('');
});


$("#deleteorganisationbtn").on('click', function (e) {
    $.ajax({
        type : 'DELETE',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url : "/organisation/"+$("#orgid").val(),
        data:{
            'id':  $("#orgid").val(),
        },
        success:function(data){
            $('#orgid').val('');
            $("#deleteorgname").html('');
            $('#deleteorganisationModal').modal('hide')
            //relationsindex.draw();
            location.reload() //temp fix
        },
        error:function(data) {
            //
        }
    });
});


$('.select2').select2({
    placeholder: 'Maak een keuze',
    //selectOnClose: true,
    closeOnSelect: false,
});


function TabByHash(hash) {
    var myTab = $(hash);
    if (myTab.length != 0) {
        var topTab = myTab.parent().closest('.pane');
        if (topTab.length != 0) {
            $('a[href=\\#' + topTab.attr('id') + ']').click();
        }
        $('a[href=\\#' + myTab.attr('id') + ']').click();
    }
}


var hash = window.location.hash;
TabByHash(hash);
